import { trackOnTargetClick, createSiteMerchandisingEntry } from '../analytics-track'

const addOnTargetClick = (page, publicRuntimeConfig) => ({ item, parent }) => {
  const entry = createSiteMerchandisingEntry(item, page.pageKey, parent, item.additionalCTA)
  if (!entry) return

  const {
    brand: { brandId: brand },
    gtmAnalytics
  } = publicRuntimeConfig

  const { ynapParameter, layoutVariant } = item

  const onTargetClick = () => {
    // lock this down for this A/B test specifc before it is modulized
    if ((layoutVariant !== 'osc-block' && layoutVariant !== 'promo-countdown') || !ynapParameter) {
      return trackOnTargetClick(entry, gtmAnalytics, brand)
    }

    let optimizelyParam
    try {
      optimizelyParam = JSON.parse(ynapParameter).optimizelyParam
    } catch (error) {
      optimizelyParam = ynapParameter
    }

    if (optimizelyParam.track) {
      const optimizely = window?.optimizelyCreateInstance
      optimizely?.track(optimizelyParam.track)
    }

    trackOnTargetClick(entry, gtmAnalytics, brand)
  }

  return { onTargetClick }
}

export default addOnTargetClick
